
$(function(){

	Hyphenator.run();

	//=require includes/menus.js

	//=XXXrequire includes/formularios.js

	//=require includes/sliders.js

	//=XXXrequire includes/noticias.js

	//=XXXrequire includes/calendarios.js

	//=XXXrequire includes/rutas.js





});
